const HTTP_URL=process.env.VUE_APP_API
module.exports = {
  payconfig:`${HTTP_URL}shop/index/getConfig`,

  createOrder:`${HTTP_URL}shop/index/createOrder`,
  getOrderStatus:`${HTTP_URL}shop/index/getOrderStatus`,

  finishOrder:`${HTTP_URL}shop/index/finishOrder`,

}
